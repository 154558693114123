import { graphql } from 'gatsby'
import * as React from 'react'
import Header from '../components/header'
import Layout from '../components/layout'
import NextPage from '../components/nextPage'
import OrderedList from '../components/orderedListNews'
import ScrollingTextBanner from '../components/scrollingTextBanner'
import SEO from '../components/seo'
import Spacer from '../components/spacer'

class NewsPage extends React.Component {
	render() {
		const news = this.props.data.datoCmsNews.newsArticles.map(item => ({ node: item }))
		const bannerImage = this.props.data.datoCmsNews.image?.gatsbyImageData
		const nextPage = this.props.data.datoCmsNews
		const seo = news.seo
		return (
			<Layout className='pth'>
				<SEO title={seo?.title || 'News on Siera Group'} description={seo?.description} image={seo?.image?.url} />
				<Header page='News' />
				<ScrollingTextBanner title='News' className='h-a' image={bannerImage} textClassName='' />
				<OrderedList target='_blank' secondClassName='text-initial' items={news} />
				<Spacer />
				<NextPage title={nextPage.nexttitle} link={nextPage.nextlink} image={nextPage.nextimage} />
			</Layout>
		)
	}
}

export default NewsPage

export const query = graphql`
	query News {
		datoCmsNews {
			image {
				gatsbyImageData
			}
			nexttitle
			nextlink
			nextimage {
				gatsbyImageData
			}
			seo {
				description
				title
				image {
					url
				}
			}
			newsArticles {
				year
				location: publication
				info
				id
				title: date
				link
				featureImage: image {
					gatsbyImageData
				}
			}
		}
	}
`
